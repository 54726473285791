$breakpoint-tablet: 768px;
@media (max-width: $breakpoint-tablet) {
  .m-h {
    display: none;
  }
  .m-ml-10 {
    margin-left: 10px;
  }
  .m-mr-10 {
    margin-right: 10px;
  }
  .m-ml-20 {
    margin-left: 20px;
  }
  .m-mr-20 {
    margin-right: 20px;
  }
}
@media (min-width: $breakpoint-tablet) {
  .d-h {
    display: none;
  }
}
.desktop-container-fluid {
  margin-top: 50px;
}

.notification {
  bottom: 30% !important;
  margin-left: 10%;
  width: 80% !important;
}

@media only screen and (min-width: 62em) {
  .ReactModal__Content {
    max-width: 500px;
    left: 30% !important;
    margin: 50px;
    max-height: 80%;
  }
  .notification {
    width: 100% !important;
  }

  .align-modal-center .ReactModal__Content {
    bottom: 30% !important;
  }
  .d-mt-10 {
    margin-top: 10px;
  }
  .d-mr-10 {
    margin-right: 10px;
  }
  .d-ml-10 {
    margin-left: 10px;
  }
  .d-p-10 {
    padding: 10px;
  }
  .d-pl-30 {
    padding-left: 30px;
  }
  .d-pr-30 {
    padding-right: 30px;
  }
  .d-m-200 {
    margin: auto 200px;
  }
  html[dir='rtl'] {
    .d-pr-10 {
      padding-right: inherit;
      padding-left: 10px;
    }
    .d-br-light {
      border-left: solid 1px var(--light);
    }
  }
  html[dir='ltr'] {
    .d-br-light {
      border-right: solid 1px var(--light);
    }
  }
  .desktop-container-fluid {
    margin-right: auto;
    margin-left: auto;
    max-width: 1200px;
    // padding-right: 2rem;
    // padding-left: 2rem;
  }
  .d-mh-400 {
    min-height: 400px;
  }
}

html[dir='rtl'] {
  .rtl-reverse {
    transform: rotate(180deg);
  }
  .rtl-ta-right {
    text-align: right;
  }
}

html[dir='ltr'] {
  .ltr-reverse {
    transform: rotate(180deg);
  }
}
img.img-fluid {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border-style: none;
}
