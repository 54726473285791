html[dir='rtl'] body,
html[dir='rtl'] body * {
  font-family: 'Swissra', sans-serif;
  line-height: 1.6;
}

html[dir='ltr'] body,
html[dir='ltr'] body * {
  font-family: 'Proxima Nova', sans-serif;
}

html._no-scroll,
html._no-scroll body {
  height: 100%;
  overflow: hidden;
}
body {
  margin: 0 auto;
  padding-bottom: env(safe-area-inset-bottom);
}
body.ReactModal__Body--open {
  overflow: hidden;
  height: 100%;
}
*[role='button'],
*[role='link'] {
  cursor: pointer;
}
.bb-light {
  border-bottom: solid 1px var(--light);
}
.bb-light-4 {
  border-bottom: solid 4px var(--light);
}

.bb-brand {
  border-bottom: solid 1px var(--brand-color);
}
.bb-brand-dashed {
  border-bottom: dashed 1px var(--brand-color);
}

.bb-brand-2px {
  border-bottom: solid 2px var(--brand-color);
}
.b-brand-dark {
  border: 1px solid var(--brand-dark-color);
}
.b-white {
  border: 1px solid var(--white);
}

.b-white-four {
  border: 1px solid var(--white-four);
}
.b-light {
  border: 1px solid var(--light);
}

.b-dark {
  border: 1px solid #aaaaaa;
}

.b-dark-gray {
  border: 1px solid var(--dark-gray);
}
.brand-border {
  border: 1px solid var(--brand-color);
}

.b-white-two {
  border: 1px solid var(--white-two);
}
.bt-white-two {
  border-top: 1px solid var(--white-two);
}
.bb-white-two {
  border-bottom: 1px solid var(--white-two);
}

.b-none {
  border: none;
}

.b-light-gray {
  border: 1px solid var(--very-light-pink-gray);
}

.b-brown-grey {
  border: 1px solid var(--brown-grey);
}
.bt-light-gray {
  border-top: 1px solid var(--very-light-pink-gray);
}

.br-light-gray {
  border-right: 1px solid var(--very-light-pink-gray);
}

.bl-light-gray {
  border-left: 1px solid var(--very-light-pink-gray);
}

.bb-light-gray {
  border-bottom: 1px solid var(--very-light-pink-gray);
}

.bb-transparent {
  border-bottom: 1px solid transparent;
}

.b-icon-black-normal {
  stroke: var(--black);
  stroke-width: 30;
}

.b-icon-accent-normal {
  stroke: var(--accent-color);
  stroke-width: 30;
}

.br-normal {
  border-radius: 11.1px;
}
.b-dashed-brand {
  border: 1px dashed var(--brand-color);
}

.br-50p {
  border-radius: 50%;
}

a {
  text-decoration: none;
}

.bt-light {
  border-top: solid 1px var(--light);
}

.br-light {
  border-right: solid 1px var(--light);
}

.br-brown-gray {
  border-right: solid 1px var(--brownish-gray);
}

.bl-brown-gray {
  border-left: solid 1px var(--brownish-gray);
}

.br-0 {
  border-radius: 0px !important;
}
.br-1 {
  border-radius: 1px;
}

.br-4 {
  border-radius: 4px;
}

.br-8 {
  border-radius: 8px;
}
.br-20 {
  border-radius: 20px;
}

.truncate {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

hr {
  border: none;
  height: 1px;
  background-color: #eee;
}

.expandable {
  transition: all 0.5s ease-in-out;
  transform-origin: left top;
  transform: scaleY(0);
}

.ripple {
  background-position: center;
  transition: background 0.8s;
}

.ripple:hover {
  //   background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%)
  //     center/15000%;
  background-image: radial-gradient(
    circle,
    transparent 1%,
    rgb(71, 167, 245) 1%
  );
}

.ripple:active {
  background-color: #6eb9f7;
  background-size: 100%;
  transition: background 0s;
}

div:focus {
  outline: none;
}

.loader-spin {
  -webkit-animation: fa-spin 0.5s infinite linear;
  animation: fa-spin 0.5s infinite linear;
}

.shine {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f5f5f5;
  background: linear-gradient(
    70deg,
    #f5f5f5 5%,
    #f5f5f5 15%,
    #00000017 30%,
    #f5f5f5 45%,
    #f5f5f5 55%
  );
  background-size: 1200px 100%;
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}

.shine span,
.shine .shine-img {
  opacity: 0;
}

.invisible {
  visibility: hidden;
}

.shine.txt-overflow-ellipsis {
  text-overflow: clip;
}

.overflow-auto {
  overflow: auto;
}

.overflow-auto-no-bar {
  white-space: nowrap;
  overflow: auto;
  -ms-overflow-style: none;
}
.overflow-auto-no-bar::-webkit-scrollbar {
  display: none;
}
.outline-none {
  outline: none;
}

.rot-45 {
  transform: rotate(45deg);
}

.rot-45n {
  transform: rotate(-25deg);
}

.rot-180 {
  transform: rotate(180deg);
}

html[dir='ltr'] {
  .br-l-10 {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }
  .br-r-10 {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  .br-r-white-two {
    border-right: 1px solid var(--white-two);
  }
}
html[dir='rtl'] {
  .rot-45 {
    transform: rotate(135deg);
  }

  .br-l-10 {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
  .br-r-10 {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }
  .br-r-white-two {
    border-left: 1px solid var(--white-two);
  }
}
.cursor-default {
  cursor: default;
}
.cursor-pointer {
  cursor: pointer;
}

.transition-03 {
  transition: all 0.3s ease-in-out;
}

.transition-06 {
  transition: all 0.6s ease-in-out;
}

.o-0 {
  opacity: 0;
}

.o-1 {
  opacity: 1;
}

.o-25 {
  opacity: 0.25;
}

.o-40 {
  opacity: 0.4;
}
.o-50 {
  opacity: 0.5;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}
.z-101 {
  z-index: 101;
}

.h-scroll {
  white-space: nowrap;
  overflow: auto;
}

.h-scroll > * {
  display: inline-block;
}

.b-box {
  box-sizing: border-box;
}

.pointer-e-none {
  pointer-events: none;
}
.pointer-e-all {
  pointer-events: all;
}
.input-pw {
  -webkit-text-security: disc;
  -moz-webkit-text-security: disc;
  -moz-text-security: disc;
}
// .App {
//   text-align: center;
// }
// .App-logo {
//   animation: App-logo-spin infinite 20s linear;
//   height: 40vmin;
//   pointer-events: none;
// }
// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }
// .App-link {
//   color: #61dafb;
// }
// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }
