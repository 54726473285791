.en-font,
html[dir='ltr'] body .en-font,
html[dir='rtl'] body .en-font,
html[dir='rtl'] body .en-font * {
  font-family: 'Proxima Nova', sans-serif;
}
.ar-font,
html[dir='ltr'] body .ar-font,
html[dir='ltr'] body .ar-font *,
html[dir='rtl'] body .ar-font {
  font-family: 'Swissra', sans-serif;
}

html[dir='rtl'] body .en-font,
html[dir='rtl'] body .en-font * {
  line-height: normal;
}
.forced-rtl {
  direction: rtl;
}
.forced-ltr {
  direction: ltr;
}
.forced-ltr-p {
  direction: ltr;
}

.fs-0 {
  font-size: 0px;
}
.fs-13 {
  font-size: 13px;
}
.fs-8 {
  font-size: 8px;
}
.fs-9 {
  font-size: 9px;
}
.fs-11 {
  font-size: 11px;
}
.fs-15 {
  font-size: 15px;
}
.fs-10 {
  font-size: 10px;
}
.fs-12 {
  font-size: 12px;
}
.fs-20 {
  font-size: 20px;
}
.fs-22 {
  font-size: 22px;
}
.fs-25 {
  font-size: 25px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-21 {
  font-size: 21px;
}
.fs-50 {
  font-size: 50px;
}
.strike {
  text-decoration: line-through;
}
.cap {
  text-transform: uppercase;
}
.tt-c {
  text-transform: capitalize;
}
.txt-overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.overflow-hidden {
  overflow: hidden;
}
.td-none {
  text-decoration: none;
}
.error {
  color: var(--error);
}
html[dir='ltr'] {
  .fw-5 {
    font-weight: 500;
  }
  .fw-6 {
    font-weight: 600;
  }
  .ls-12 {
    letter-spacing: 1.2px;
  }
  .ls-14 {
    letter-spacing: 1.4px;
  }
  .ls-024 {
    letter-spacing: 0.24px;
  }
  .ls-043 {
    letter-spacing: 0.43px;
  }
  .ls-048 {
    letter-spacing: 0.48px;
  }
  .ls-04 {
    letter-spacing: 0.4px;
  }

  .ls-032 {
    letter-spacing: 0.32px;
  }
  .ls-051 {
    letter-spacing: 0.51px;
  }
  .ls-072 {
    letter-spacing: 0.72px;
  }
  .ls-08 {
    letter-spacing: 0.8px;
  }
  .ls-096 {
    letter-spacing: 0.96px;
  }
  .ls-16 {
    letter-spacing: 1.6px;
  }
}
html[dir='rtl'] {
  .forced-ltr-p > * {
    direction: rtl;
  }
  .fw-5 {
    font-weight: 500;
  }
  .fw-6 {
    font-weight: 500;
  }
}
