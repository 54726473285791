.r-br-light {
  border-right: solid 1px var(--light);
}
.r-ta-l {
  text-align: left;
}
.r-ta-r {
  text-align: right;
}
.r-pr-10 {
  padding-right: 10px;
}
.r-pl-56 {
  padding-left: 56px;
}
.r-pl-10 {
  padding-left: 10px;
}
.r-ml-5 {
  margin-left: 5px;
}
.r-ml-8 {
  margin-left: 8px;
}
.r-ml-15 {
  margin-left: 15px;
}
.r-ml-16 {
  margin-left: 16px;
}

.r-mr-10 {
  margin-right: 10px;
}
.r-r-0 {
  right: 0px;
}

html[dir='rtl'] {
  .r-pl-56 {
    padding-right: 56px;
  }
  .r-r-0 {
    left: 0px;
    right: inherit;
  }
  .r-ml-5 {
    margin-left: inherit;
    margin-right: 5px;
  }
  .r-ml-8 {
    margin-left: inherit;
    margin-right: 8px;
  }
  .r-ml-15 {
    margin-left: inherit;
    margin-right: 15px;
  }
  .r-ml-16 {
    margin-left: inherit;
    margin-right: 16px;
  }
  .r-pr-10 {
    padding-right: inherit;
    padding-left: 10px;
  }
  .r-pl-10 {
    padding-left: inherit;
    padding-right: 10px;
  }

  .r-mr-10 {
    margin-right: inherit;
    margin-left: 10px;
  }
  .r-br-light {
    border-left: solid 1px var(--light);
  }
  .r-ta-l {
    text-align: right;
  }
  .r-ta-r {
    text-align: left;
  }
}
