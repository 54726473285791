:root {
  --brand-color: #c4a64d;
  --brand-dark-color: #978039;
  --brand-color-o-15: rgba(196, 166, 77, 0.15);
  --brand-color-o-10: rgba(196, 166, 77, 0.1);
  --brand-light-color: #f7f1e2;
  --accent-color: #f25139;
  --black-10: rgba(0, 0, 0, 0.1);
  --black-50: rgba(0, 0, 0, 0.5);
  --medium-grey: #5d5e5c;
  --grey: #929391;
  --brown-grey: #a2a2a2;
  --light-sage: #c8c9c6;
  --off-white: #ecedeb;
  --off-white: #f7f8f7;
  --error: #b81d5b;
  --error-bg: #ffd6d9;
  --error-bk: #fcdcd7;
  --success: #46b275;
  --avocado-green: #73ad27;
  --alert-popup: #fa6400;
  --success-bk: #daf0e3;
  --butterscotch: #ffc043;
  --pale-yellow: #fff2d9;
  --white: #ffffff;
  --black: #000000;
  --orange: #ff7d49;
  --light: #e5e5e5;
  --high-color: #282828;
  --light-bg: #f4f4f4;
  --main-gray: #424242;
  --dark-gray: #2d2d2d;
  --icon: #d3d0d0;
  --checkbox: #e0e0e0;
  --error-icon: #eb966d;
  --deal: #32a68c;
  --black-light: #1b1b1b;
  --sap-green: #4f7c14;
  --sap-green-25: rgba(96, 150, 25, 0.25);
  --sap-green-o-08: rgba(79, 124, 20, 0.08);
  --header-title: #333333;
  --lighgt-gray: #f9f9f9;
  --white-four: #f1f1f1;
  --pastel-red: #df5757;
  --pastel-red-two: #db6360;
  --pastel-red-two-o-10: rgba(219, 99, 96, 0.1);
  --watermelon: #f15152;
  --pale-salmon: #febab8;
  --very-light-pink-gray: #dddddd;
  --very-light-pink-two: #bdbdbd;
  --brownish-gray: #777777;
  --white-two: #d8d8d8;
  --white-three: #fafafa;
  --white-o-05: rgba(255, 255, 255, 0.5);
  --white-o-065: rgba(255, 255, 255, 0.65);
  --soft-blue: rgba(97, 115, 232, 0.24);
  --secondary-neutral-08: rgba(97, 115, 232, 0.08);
  --selected-tab-gray: rgba(0, 0, 0, 0.2);
  --selected-tab-red: rgba(245, 0, 87, 0.2);
  --soft-blue-o: rgba(97, 115, 232);
  --light-gray-1: #f6f6f6;
  --warm-gray: #8d8d8d;
  --soft-blue-15: rgb(97, 115, 232, 0.15);
  --sea: #309a82;
  --secondary-negative: #d84753;
  --secondary-neutral: #6173e8;
  --brand-1: #44d7b6;
  --brand-2: #6236ff;
  --brand-3: #f7b500;
}

.c-b1 {
  color: var(--brand-1);
}
.c-b2 {
  color: var(--brand-1);
}
.c-b3 {
  color: var(--brand-1);
}
.bg-b1 {
  background-color: var(--brand-1);
}
.bg-b2 {
  background-color: var(--brand-2);
}
.bg-b3 {
  background-color: var(--brand-3);
}
.c-header-title {
  color: var(--header-title);
}

.c-header-sub-title {
  color: rgba(119, 119, 119, 1);
}

.c-label-hint {
  color: var(--brown-grey);
}

.c-dark {
  color: var(--high-color);
}

.c-high {
  color: var(--high-color);
}

.c-brown-gray {
  color: var(--brownish-gray);
}

.c-light-gray {
  color: var(--very-light-pink-gray);
}

.c-main-gray {
  color: var(--main-gray);
}

.c-dark-gray {
  color: var(--dark-gray);
}

.c-soft-blue {
  color: var(--soft-blue-o);
}

.c-warm-gray {
  color: var(--warm-gray);
}

.c-white {
  color: var(--white);
}

.c-icon {
  color: var(--icon);
}

.c-checkbox {
  color: var(--brown-grey);
}

.c-brand {
  color: var(--brand-color);
}

.c-accent {
  color: var(--accent-color);
}

.c-black-10 {
  color: var(--black-10);
}

.c-black-50 {
  color: var(--black-50);
}

.c-error-icon {
  color: var(--error-icon);
}

.c-red {
  color: #f25139;
}

.c-pastel-red {
  color: var(--pastel-red);
}
.c-watermelon {
  color: var(--watermelon);
}
.c-error {
  color: var(--error);
}

.c-deal {
  color: var(--deal);
}

.c-black-light {
  color: var(--black-light);
}

.c-light-sage {
  color: var(--light-sage);
}

.c-sap-green {
  color: var(--sap-green);
}
.c-very-light-pink-two {
  color: var(--very-light-pink-two);
}
.c-pastel-red-two {
  color: var(--pastel-red-two);
}
.c-secondary-neutral {
  color: var(--secondary-neutral);
}
.c-secondary-negative {
  color: var(--secondary-negative);
}
.bg-soft-Blue {
  background-color: var(--soft-blue);
}
.bg-soft-Blue-15 {
  background-color: var(--soft-blue-15);
}
.bg-light {
  background-color: var(--light-bg);
}
.bg-sap-green {
  background-color: var(--sap-green);
}
.bg-sap-green-25 {
  background-color: var(--sap-green-25);
}
.bg-sap-green-o-08 {
  background-color: var(--sap-green-o-08);
}
.bg-dark-gray {
  background-color: var(--dark-gray);
}
.bg-brown-gray {
  background-color: var(--brownish-gray);
}

.bg-white {
  background-color: var(--white);
}

.bg-transparent {
  background: transparent;
}

.bg-error {
  background-color: var(--error-bg);
}

.bg-brand {
  background-color: var(--brand-color);
}
.bg-brand-hov:hover {
  background-color: var(--brand-color);
}
.bg-brand-color-o-15 {
  background-color: var(--brand-color-o-15);
}
.bg-brand-color-o-10 {
  background-color: var(--brand-color-o-10);
}

.bg-brand-light {
  background-color: var(--brand-light-color);
}

.bg-light-gray {
  background-color: var(--lighgt-gray);
}

.bg-light-gray-1 {
  background-color: var(--light-gray-1);
}

.bg-thumb-gray {
  background-color: var(--white-four);
}

.bg-very-light-pink-gray {
  background-color: var(--very-light-pink-gray);
}

.icon-bg-c-l-20 {
  background: #eeeeee;
  border-radius: 50%;
  padding: 2px 5px;
}

.bg-white-three {
  background: var(--white-three);
}
.bg-white-o-05 {
  background: var(--white-o-05);
}
.bg-white-o-065 {
  background: var(--white-o-065);
}
.bg-pastel-red {
  background-color: var(--pastel-red);
}

.bg-pastel-red-two {
  background-color: var(--pastel-red-two);
}
.bg-pastel-red-two-o-10 {
  background-color: var(--pastel-red-two-o-10);
}
.bg-pale-salmon {
  background: var(--pale-salmon);
}
.bg-white-two {
  background: var(--white-two);
}
.bg-secondary-negative {
  background: var(--secondary-negative);
}
.bg-secondary-neutral-08 {
  background: var(--secondary-neutral-08);
}

.bg-selected-tab-gray {
  background: var(--selected-tab-gray);
}

.bg-selected-tab-red {
  background: var(--selected-tab-red);
}
.c-pastel-red-two {
  color: var(--pastel-red-two);
}
.c-secondary-negative {
  color: var(--secondary-negative);
}

.c-wbgrm-gray {
  background: var(--warm-gray);
}
.c-avocado-green {
  color: var(--avocado-green);
}
.c-sea {
  color: var(--sea);
}
