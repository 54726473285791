.v-t {
  vertical-align: top;
}

.v-m {
  vertical-align: middle;
}

.ta-c {
  text-align: center;
}

.ta-l {
  text-align: left;
}

.ta-r {
  text-align: right;
}

.lh-normal {
  line-height: 1.83;
}
.flip {
  transform: scaleX(-1);
}

html[dir='rtl'] {
  .rtl-r {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .rtl-flip {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .start-10 {
    right: 10px;
  }
  .end-10 {
    left: 10px;
  }
  .end-20 {
    left: 20px;
  }
  .start-0 {
    right: 0px;
  }
  .end-0 {
    left: 0px;
  }

  .lh-1_5 {
    line-height: 1.8;
  }
  .lh-14 {
    line-height: 18px;
  }
  .lh-15 {
    line-height: 19px;
  }
  .lh-16 {
    line-height: 20px;
  }
  .lh-18 {
    line-height: 22px;
  }
  .lh-20 {
    line-height: 24px;
  }
  .lh-24 {
    line-height: 28px;
  }
  .lh-46 {
    line-height: 50px;
  }
  .lh-ar-08 {
    line-height: 0.8px;
  }
  .lh-ar-18 {
    line-height: 18px;
  }
  .lh-ar-19 {
    line-height: 19px;
  }
  .lh-ar-2 {
    line-height: 2 !important;
  }
}

html[dir='ltr'] {
  .start-10 {
    left: 10px;
  }
  .end-10 {
    right: 10px;
  }
  .end-20 {
    right: 20px;
  }
  .start-0 {
    left: 0px;
  }
  .end-0 {
    right: 0px;
  }

  .lh-1_5 {
    line-height: 1.5;
  }
  .lh-14 {
    line-height: 14px;
  }
  .lh-15 {
    line-height: 15px;
  }
  .lh-16 {
    line-height: 16px;
  }
  .lh-18 {
    line-height: 18px;
  }
  .lh-20 {
    line-height: 20px;
  }
  .lh-24 {
    line-height: 24px;
  }
  .lh-46 {
    line-height: 46px;
  }
}

.t-0 {
  top: 0px;
}
.t-8 {
  top: 8px;
}

.t-100p {
  top: 100%;
}

.l-0 {
  left: 0px;
}

.l-4 {
  left: 4px;
}

.r-4 {
  right: 4px;
}
.r-5 {
  right: 5px;
}

.r-0 {
  right: 0px;
}

.bottom-fixed {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0;
  padding-bottom: env(safe-area-inset-bottom);
}

.top-fixed {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0;
}

.screen-fixed {
  position: fixed;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0px;
}

.fixed-start-top {
  position: fixed;
  top: 0px;
  right: auto;
  bottom: auto;
  left: 0px;
}

.fixed-end-top {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: auto;
  left: auto;
}

.ReactModalPortal {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.pos-relt {
  position: relative;
}

.pos-fix {
  position: fixed;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.pull-right {
  float: right;
}
.ws-nowrap {
  white-space: nowrap;
}
.ws-normal {
  white-space: normal;
}
.wb-ball {
  word-break: break-all;
}
html[dir='rtl'] {
  .justify-start {
    margin-right: auto;
  }
  .fixed-start-top {
    right: 0;
    left: auto;
  }
  .fixed-end-top {
    right: auto;
    left: 0;
  }
}

html[dir='ltr'] {
  .justify-start {
    margin-left: auto;
  }
  .pull-left-r {
    float: left;
  }
}
html[dir='rtl'] {
  .pull-left-r {
    float: right;
  }
}

@media all and (min-width: 1025px) {
  body {
    margin: 0 auto;
  }

  .bottom-fixed,
  .top-fixed,
  .slide-pane__content,
  .search-box,
  .addToBagAnimImg {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
  }
}
.odj-fit-cover {
  object-fit: cover;
}
