.Button {
  border-radius: 2px;
  outline: none;
  cursor: pointer;

  &.primary {
    background-color: rgba(45, 45, 45, 1);
    &:disabled {
      background-color: #a2a2a2;
    }
  }
  &.secondary {
    background-color: #424242;
    .Action {
      color: #ffffff;
    }
    &:disabled {
      background-color: #a2a2a2;
    }
  }
  &.default {
    background-color: #ffffff;
    border: 1px solid #dbd0d0;
  }

  &.transparent {
    background-color: transparent;
    border: 0px solid transparent;
  }

  .Action {
    // font-size: 14px;
    // font-weight: 400;
    line-height: 1.71;

    text-align: center;
  }

  &.primary {
    .Action {
      color: var(--white);
    }
  }
  &.full-width {
    width: 100%;
  }
  &.no-padding {
    padding: 0px;
  }
  &.bg-light {
    background-color: var(--light-bg);
  }
  &.pt-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
html[dir='ltr'] .Button .Action {
  letter-spacing: 0.72px;
}
