.m-0 {
  margin: 0px;
}
.m-5 {
  margin: 5px;
}
.m-7 {
  margin: 7px;
}
.m-10 {
  margin: 10px;
}
.m-16 {
  margin: 16px;
}
.m-15 {
  margin: 15px;
}
.m-25 {
  margin: 25px;
}
.mt-2 {
  margin-top: 2px;
}
.mt-3 {
  margin-top: 3px;
}

.mt-4 {
  margin-top: 4px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-8 {
  margin-top: 8px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-14 {
  margin-top: 14px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mt-30 {
  margin-top: 30px;
}
.mb-10 {
  margin-bottom: 10px;
}
.ml-n2 {
  margin-left: -2px;
}
.ml-3 {
  margin-left: 3px;
}
.ml-4 {
  margin-left: 4px;
}
.ml-n4 {
  margin-left: -4px;
}
.ml-5 {
  margin-left: 5px;
}

.ml-7 {
  margin-left: 7px;
}
.ml-8 {
  margin-left: 8px;
}

.ml-n8 {
  margin-left: -8px;
}
.ml9-5p {
  margin-left: 9.5%;
}

.mr-n2 {
  margin-right: -2px;
}
.mr-3 {
  margin-right: 3px;
}
.mr-4 {
  margin-right: 4px;
}
.mr-n4 {
  margin-right: -4px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-7 {
  margin-right: 7px;
}
.mr-8 {
  margin-right: 8px;
}

.mr-n7 {
  margin-right: -7px;
}
.mr-n8 {
  margin-right: -8px;
}

.ml-n7 {
  margin-left: -7px;
}

.ml-10 {
  margin-left: 10px;
}
.ml-12 {
  margin-left: 12px;
}

.mr-10 {
  margin-right: 10px;
}
.mr-12 {
  margin-right: 12px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-n15 {
  margin-left: -15px;
}
.ml-16 {
  margin-left: 16px;
}
.ml-n16 {
  margin-left: -16px;
}

.mr-15 {
  margin-right: 15px;
}
.mr-16 {
  margin-right: 16px;
}
.mr-n-12 {
  margin-right: -12px;
}
.mr-n-15 {
  margin-right: -15px;
}
.mr-n15 {
  margin-right: -15px;
}
.mr-n16 {
  margin-right: -16px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.ml-n9 {
  margin-left: -9px;
}
.ml-n15 {
  margin-left: -15px;
}

.mr-n15 {
  margin-right: -15px;
}
.mr-n9 {
  margin-right: -9px;
}

.ml-n25 {
  margin-left: -25px;
}
.ml-n-12 {
  margin-left: -12px;
}
.ml-n-15 {
  margin-left: -15px;
}
.mr-n-15 {
  margin-right: -15px;
}
.mr-n25 {
  margin-right: -25px;
}

.ml-30 {
  margin-left: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-40 {
  margin-right: 40px;
}
.mt-14 {
  margin-top: 14px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-18 {
  margin-top: 18px;
}

.mb-13 {
  margin-bottom: 13px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-n30 {
  margin-bottom: -30px;
}
.mt-n-1p {
  margin-top: -1%;
}
.mt-n-5p {
  margin-top: -5.5%;
}
.mt-n-3p {
  margin-top: -3.5%;
}
.mt-n-2p {
  margin-top: -2.5%;
}
.mt-n-15 {
  margin-top: -15px;
}
.mt-n-25 {
  margin-top: -25px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}
.mt-27 {
  margin-top: 27px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}
.mt-55 {
  margin-top: 55px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-73 {
  margin-top: 73px;
}
.mt-75 {
  margin-top: 75px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-83 {
  margin-top: 83px;
}
.mt-85 {
  margin-top: 85px;
}
.mt-88 {
  margin-top: 88px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-180 {
  margin-top: 180px;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-4 {
  margin-bottom: 4px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-7 {
  margin-bottom: 7px;
}
.mb-8 {
  margin-bottom: 8px;
}

.mb-2 {
  margin-bottom: 2px;
}

.mb-16 {
  margin-bottom: 16px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-32 {
  margin-bottom: 32px;
}

.mb-50 {
  margin-bottom: 50px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}
.mb-75 {
  margin-bottom: 75px;
}

.mb-100 {
  margin-bottom: 100px;
}
.mb-120 {
  margin-bottom: 120px;
}
.mb-130 {
  margin-bottom: 130px;
}

.m-a {
  margin: auto;
}

.mt-0 {
  margin-top: 0px;
}
.mt-3 {
  margin-top: 3px;
}

.p-0 {
  padding: 0px;
}

.p-3 {
  padding: 3px;
}
.p-4 {
  padding: 4px;
}
.p-5 {
  padding: 5px;
}

.p-8 {
  padding: 8px;
}
.p-10 {
  padding: 10px;
}

.p-14 {
  padding: 14px;
}

.p-15 {
  padding: 15px;
}
.p-16 {
  padding: 16px;
}

.p-20 {
  padding: 20px;
}
.p-25 {
  padding: 25px;
}

.pl-2 {
  padding-left: 2px;
}
.pl-3 {
  padding-left: 3px;
}

.pr-2 {
  padding-right: 2px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-4 {
  padding-left: 4px;
}

.pr-5 {
  padding-right: 5px;
}
.pr-3 {
  padding-right: 3px;
}
.pr-4 {
  padding-right: 4px;
}
.pl-7 {
  padding-left: 7px;
}

.pr-7 {
  padding-right: 7px;
}

.pl-8 {
  padding-left: 8px;
}

.pr-8 {
  padding-right: 8px;
}

.pr-10 {
  padding-right: 10px;
}

.pl-10 {
  padding-left: 10px;
}
.pl-12 {
  padding-left: 12px;
}
.pr-12 {
  padding-right: 12px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-16 {
  padding-right: 16px;
}

.pl-15 {
  padding-left: 15px;
}
.pl-16 {
  padding-left: 16px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pl-30 {
  padding-left: 30px;
}
.pl-40 {
  padding-left: 40px;
}
.pl-56 {
  padding-left: 56px;
}

.pl-50 {
  padding-left: 50px;
}

.pr-30 {
  padding-right: 30px;
}
.pr-50 {
  padding-right: 50px;
}

.pr-0 {
  padding-right: 0px;
}

.pt-0 {
  padding-top: 0px;
}

.pt-2 {
  padding-top: 2px;
}

.pt-3 {
  padding-top: 3px;
}

.pt-4 {
  padding-top: 4px;
}
.pt-5 {
  padding-top: 5px;
}

.pt-6 {
  padding-top: 6px;
}

.pt-7 {
  padding-top: 7px;
}

.pt-8 {
  padding-top: 8px;
}

.pt-10 {
  padding-top: 10px;
}
.pt-12 {
  padding-top: 12px;
}

.pt-14 {
  padding-top: 14px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-16 {
  padding-top: 16px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-24 {
  padding-top: 24px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-32 {
  padding-top: 32px;
}

.pb-0 {
  padding-bottom: 0px;
}
.pb-2 {
  padding-bottom: 3px;
}
.pb-3 {
  padding-bottom: 3px;
}

.pb-4 {
  padding-bottom: 4px;
}
.pb-5 {
  padding-bottom: 5px;
}

.pb-6 {
  padding-bottom: 6px;
}

.pb-7 {
  padding-bottom: 7px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pb-10 {
  padding-bottom: 10px;
}
.pb-12 {
  padding-bottom: 12px;
}
.pb-14 {
  padding-bottom: 14px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-16 {
  padding-bottom: 16px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-24 {
  padding-bottom: 24px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-32 {
  padding-bottom: 32px;
}

.pb-50 {
  padding-bottom: 50px;
}
.pb-55 {
  padding-bottom: 55px;
}
.pb-70 {
  padding-bottom: 70px;
}

.w80p {
  width: 80%;
}

.w90p {
  width: 90%;
}

.w100p {
  width: 100%;
}

.w45p {
  width: 45%;
}

.w50p {
  width: 50%;
}

.w55p {
  width: 55%;
}

.w25 {
  width: 25px;
}

.w40 {
  width: 40px;
}
.w45 {
  width: 45px;
}

.w50 {
  width: 50px;
}
.w72 {
  width: 72px;
}
.w80 {
  width: 80px;
}
.w100 {
  width: 100px;
}

.w250 {
  width: 250px;
}

.mw-40 {
  min-width: 40px;
}
.mw-75 {
  min-width: 75px;
}
.mw-165 {
  min-width: 165px;
}

.mh-400 {
  min-height: 400px;
}

.mh-100p {
  min-height: 100%;
}

.mh-150 {
  min-height: 150px;
}
.mh-100 {
  min-height: 100px;
}

.w100p-m25 {
  width: calc(100% - 25px);
}

.w-1 {
  width: 1px;
}
.w-2 {
  width: 2px;
}
.w-4 {
  width: 4px;
}
.w-5 {
  width: 5px;
}
.w-6 {
  width: 6px;
}
.w-8 {
  width: 8px;
}

.w-10 {
  width: 10px;
}

.w-15 {
  width: 15px;
}
.w-16 {
  width: 16px;
}

.w-20 {
  width: 20px;
}

.w-25 {
  width: 25px;
}

.w-30 {
  width: 30px;
}
.w-32 {
  width: 32px;
}

.w-40 {
  width: 40px;
}
.w-50 {
  width: 50px;
}
.w-70 {
  width: 70px;
}

.w-60 {
  width: 60px;
}
.w-75 {
  width: 75px;
}
.w-90 {
  width: 90px;
}
.w-100 {
  width: 100px;
}
.w-110 {
  width: 110px;
}
.w-120 {
  width: 120px;
}

.w-150 {
  width: 150px;
}

.w-200 {
  width: 200px;
}
.w-400 {
  width: 400px;
}
.w-20p {
  width: 20%;
}

.h-2 {
  height: 2px;
}
.h-4 {
  height: 4px;
}

.h-5 {
  height: 5px;
}

.h-8 {
  height: 8px;
}
.h-10 {
  height: 10px;
}
.h-15 {
  height: 15px;
}
.h-16 {
  height: 16px;
}
.h-18 {
  height: 18px;
}
.h-20 {
  height: 20px;
}

.h-27 {
  height: 27px;
}
.h-28 {
  height: 28px;
}
.h-32 {
  height: 32px;
}
.h-35 {
  height: 35px;
}

.h-50 {
  height: 50px;
}
.h-60 {
  height: 60px;
}
.h-80 {
  height: 80px;
}
.h-100 {
  height: 100px;
}
.h-375 {
  height: 375px;
}

.mx-w-70 {
  max-width: 70px;
}

.mx-w-250 {
  max-width: 250px;
}

.mx-w-500 {
  max-width: 500px;
}
.mx-w-30p {
  max-width: 30%;
}
.mx-w-70p {
  max-width: 70%;
}

.d-il-block {
  display: inline-block;
}

.d-block {
  display: block;
}
.d-none {
  display: none;
}

.h-25 {
  height: 25px;
}
.h-33 {
  height: 33px;
}

.h-30 {
  height: 30px;
}

.h-40 {
  height: 40px;
}
.h-45 {
  height: 45px;
}
.h-48 {
  height: 48px;
}
.h-70 {
  height: 70px;
}
.h-160 {
  height: 160px;
}
.h-200 {
  height: 200px;
}
.h-auto {
  height: auto;
}

.h100p {
  height: 100%;
}

.h100vh {
  height: 100vh;
}

.mh60vh {
  max-height: 60vh;
}

.h60vh {
  height: 60vh;
}

html[dir='ltr'] {
  .ml-auto {
    margin-left: auto;
  }
  .mr-auto {
    margin-right: auto;
  }
}
html[dir='rtl'] {
  .ml-auto {
    margin-right: auto;
  }
  .mr-auto {
    margin-left: auto;
  }
}
.otp-border {
  border: none;
  border-bottom: 1px Solid;
  text-align: center;
}
.otp-input {
  width: 15%;
  &:focus {
    box-shadow: none;
  }
}
.default-input-border {
  border: 1px solid #a0a0a0;
  &:focus {
    box-shadow: 0 1px 3px #e1e1e1;
  }
}

.border-none {
  border: none;
}
html[dir='ltr'] {
  .pr-r-8 {
    padding-right: 8px;
  }
  .pr-r-10 {
    padding-right: 10px;
  }
  .pr-r-15 {
    padding-right: 15px;
  }
  .ml-r-15 {
    margin-left: 15px;
  }
  .pl-r-16 {
    padding-left: 16px;
  }
  .pl-r-8 {
    padding-left: 8px;
  }
  .pl-r-10 {
    padding-left: 10px;
  }
  .pl-r-4 {
    padding-left: 4px;
  }
  .pl-r-5 {
    padding-left: 5px;
  }
  .pl-r-6 {
    padding-left: 6px;
  }
}
html[dir='rtl'] {
  .pr-r-8 {
    padding-left: 8px;
  }
  .pr-r-10 {
    padding-left: 10px;
  }
  .pr-r-15 {
    padding-left: 15px;
  }
  .ml-r-15 {
    margin-right: 15px;
  }
  .pl-r-16 {
    padding-right: 16px;
  }
  .pl-r-8 {
    padding-right: 8px;
  }
  .pl-r-10 {
    padding-right: 10px;
  }
  .pl-r-4 {
    padding-right: 4px;
  }
  .pl-r-5 {
    padding-right: 5px;
  }
  .pl-r-6 {
    padding-right: 6px;
  }
}
